<template>
  <v-container fluid px-11>
    <v-text-field class="mr-6" prepend-inner-icon="search" label="Search" v-model="search"></v-text-field>
    <v-data-table :search="search" class="background mr-6" :headers="headers" :items="invitations">
      <template v-slot:item.actions="{ item }">
        <v-tooltip top dark>
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="0"
              @click="openInvitationSettings(item)"
              slot="activator"
              fab
              small
              dark
              v-on="on"
              color="primary"
              :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small'"
            >
              <v-icon small>fas fa-user-cog</v-icon>
            </v-btn>
          </template>
          <span>Roles</span>
        </v-tooltip>
        <v-tooltip top dark>
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="0"
              @click="cancelInvitation(item)"
              slot="activator"
              fab
              small
              dark
              v-on="on"
              color="primary"
              :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small'"
            >
              <v-icon size="20">delete</v-icon>
            </v-btn>
          </template>
          <span>Cancel Invitation</span>
        </v-tooltip>
      </template>
      <template v-slot:item.is_corporate="{ item }">
        <v-layout row wrap ma-0 pa-0 justify-center>
          <v-checkbox disabled v-model="item.is_corporate" inset color="primary"></v-checkbox>
        </v-layout>
      </template>
    </v-data-table>
    <v-dialog
      scrollable
      v-if="editInvitation.status"
      :value="editInvitation.status"
      persistent
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="mx-0 px-3">
          <v-layout mx-1 row wrap align-center align-content-center>
            <v-icon size="18" class="mr-4">fas fa-user-cog</v-icon>Invitation Roles:
            <span
              class="ml-2 text-capitalize grey--text text--darken-1 font-weight-bold"
            >{{editInvitation.selectedInvitation.first_name}} {{editInvitation.selectedInvitation.last_name}}</span>
            <v-spacer></v-spacer>
            <v-btn @click="editInvitation.status = false" fab small icon>
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="subtitle-1 font-weight-medium">Select user roles</p>
          <span v-for="role in filteredRoles" :key="role.id">
            <v-checkbox
              v-model="role.active"
              @change="handleRoleChange(role, $event)"
              :label="role.display_name"
              hide-details
              class="pa-0 ma-0 my-1"
            ></v-checkbox>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="editInvitation.status = false"
            color="darkGrey"
            text
            class="text-capitalize"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeleteModal
      v-if="deleteDialog.status"
      :title="deleteDialog.title"
      :status="deleteDialog.status"
      :description="deleteDialog.description"
      :callback="deleteDialog.callback"
      @success="snackbar({text: 'Invitation cancelled successfully.'}), getAllOpenInvitations(),deleteDialog.status = false"
      @close="deleteDialog.status = false"
    />
  </v-container>
</template>

<script>
import { AuthAPI } from '@/clients/auth/auth.api'
import { RolesAPI } from '@/clients/permissions/roles'

export default {
  mounted () {
    this.getAllOpenInvitations()
  },
  components: {
    DeleteModal: () => import('@/components/custom-components/DeleteModal')
  },
  data () {
    return {
      search: '',
      invitations: [],
      roles: [],
      userRoles: [],
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: true,
          value: 'first_name'
        },
        {
          text: 'Last Name',
          align: 'start',
          sortable: true,
          value: 'last_name'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email'
        }, {
          text: 'Corporate',
          align: 'center',
          sortable: true,
          value: 'is_corporate'
        }, {
          text: 'Invited By',
          align: 'start',
          sortable: true,
          value: 'invited_by',
          class: 'text-capitalize'
        },
        {
          text: 'Expires on',
          align: 'start',
          sortable: true,
          value: 'expires_on'
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'actions'
        }
      ],
      editInvitation: {
        status: false,
        selectedInvitation: null
      },
      rolesSearch: '',
      deleteDialog: {
        status: false,
        title: '',
        description: 'This will cancel the invitation and you will have to re-invite the user again. Proceed?',
        callback: null
      }
    }
  },
  methods: {
    async getAllOpenInvitations () {
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.getAllOpenInvitations()
      } catch (error) {
        if (error.response.status === 404) {
          this.invitations = []
        }
        this.spinner(false)
        return console.log(error)
      }
      this.invitations = response.data.payload
      this.spinner(false)
    },
    async getRoles (id) {
      this.spinner(true)
      let response
      try {
        // response = await RolesAPI.getAllRoles()
        response = await AuthAPI.getInvitationDetails(id)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      for (let x = 0; x < response.data.payload.roles.length; x++) {
        this.userRoles = []
        if (response.data.payload.roles[x].active) {
          this.userRoles.push(response.data.payload.roles[x].id)
        }
      }
      this.roles = response.data.payload.roles
      this.spinner(false)
    },
    async openInvitationSettings (item) {
      await this.getRoles(item.invitation_id)
      this.editInvitation.selectedInvitation = item
      this.editInvitation.status = true
    },
    handleRoleChange (role, event) {
      if (event) {
        this.userRoles.push(role.id)
        this.addInvitationRole(role)
      } else {
        let elIndex = this.userRoles.indexOf(role.id)
        if (elIndex > -1) {
          this.userRoles.splice(elIndex, 1)
        }

        this.addInvitationRole(role)
      }
    },
    async addInvitationRole (role) {
      this.spinner(true)
      let data = {
        role_ids: this.userRoles
      }
      let response
      try {
        response = await AuthAPI.preAssignRoles(this.editInvitation.selectedInvitation.invitation_id, data)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.spinner(false)
    },
    async removeInvitationRole () {

    },
    async cancelInvitation (inv) {
      this.deleteDialog.status = true
      this.deleteDialog.title = `Cancel invitation for ${inv.first_name} ${inv.last_name} ?`
      this.deleteDialog.callback = () => AuthAPI.cancelAnInvitation(inv.invitation_id)
    }
  },
  computed: {
    filteredRoles () {
      if (this.search !== '') {
        return this.roles.filter(i => i.display_name.toLowerCase().includes(this.rolesSearch.toLowerCase()))
      }
      return this.roles
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
